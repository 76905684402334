.services-section {
  padding: 100px 0;
  height: 100vh;
  display: flex;
  align-items: center;
  background: var(--bg-2) url(../assets/images/service-bg-img.png) no-repeat top
    70px left 70px;
  @media (max-width: 1024.98px) {
    background: none;
    height: auto;
    background: none;
    padding: 50px 0;
  }

  @media (max-width: 430.98px) {
    padding: 20px 0;
  }

  .services-left {
    justify-content: flex-end;
    @media (max-width: 1024.98px) {
      justify-content: center;
    }
    .service-img {
      padding: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--white);
      border-radius: 60px;
      -webkit-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.09);
      -moz-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.09);
      box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.09);
      position: relative;
      // &::before {
      //     width: 100%;
      //     height: 100%;
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //     content: '';
      //     background: url(../assets/images/service-bg-img.png) no-repeat;
      // }
    }
  }
  .services-right {
    .service-list {
      margin-top: 15px;
      padding-right: 10px;
      height: 450px;
      overflow-y: auto;       
      &::-webkit-scrollbar {
          display: none;
      }
      &:hover {
        &::-webkit-scrollbar {
          display: block;
      }
      }
      &::-webkit-scrollbar-track {
        //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        background-color: var(--bg-2);
      }
      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: var(--theme_color);
      }

      @media (max-width: 767.98px) {
        height: auto;
      }
      .services {
        margin-bottom: 25px;
        position: relative;
        padding-left: 40px;
        cursor: pointer;
        &::before {
          width: 20px;
          height: 20px;
          background: var(--white);
          border: solid 1px var(--gray);
          border-radius: 50%;
          content: "";
          position: absolute;
          left: 0;
          top: 6px;
          transition: 0.3s;
        }
        &::after {
          width: 3px;
          height: calc(100% - 35px);
          background: var(--gray);
          border-radius: 2px;
          content: "";
          position: absolute;
          left: 9px;
          top: 35px;
          transition: 0.3s;
        }
        &:hover {
          &::before {
            background: var(--theme_color);
            border: solid 1px var(--theme_color);
          }
          &::after {
            background: var(--theme_color);
          }
          .service-title {            
            color: var(--theme_color);
          }
          .service-text {            
            color: var(--theme_color);
          }
        }
        &.active {
          &::before {
            background: var(--theme_color);
            border: solid 1px var(--theme_color);
          }
          &::after {
            background: var(--theme_color);
          }
          .service-title {            
            color: var(--theme_color);
          }
          .service-text {            
            color: var(--theme_color);
          }
        }
        .service-title {
          font-family: var(--secondary-font);
          font-size: 22px;
          font-weight: 700;
          color: var(--black);
          margin-bottom: 10px;
          transition: 0.3s;
        }
        .service-text {
          font-family: var(--secondary-font);
          font-size: 16px;
          font-weight: 500;
          color: var(--black);
          transition: 0.3s;
        }
      }
    }
  }
}

@-moz-document url-prefix() {
  .service-list {
    scrollbar-width: thin; // Makes the scrollbar narrower
    scrollbar-color: var(--theme_color) var(--bg-2); 
  }
  
}
