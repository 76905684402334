body {
  font-family: var(--secondary-font);
}
h1 {
  font-size: 60px !important;
  font-weight: 700 !important;
  font-family: var(--primary-font);
  color: var(--theme_color) !important;
  margin-bottom: 20px !important;
  @media (max-width: 991.98px) {
    font-size: 40px !important;
  }
}
h2 {
  font-size: 22px !important;
  font-weight: 500;
  font-family: var(--primary-font);
}
h3 {
  font-size: 20px !important;
  font-weight: 500 !important;
  font-family: var(--primary-font);
  color: var(--black) !important;
  margin-bottom: 0 !important;
}
h4 {
  font-size: 18px !important;
  font-weight: var(--font-medium) !important;
  font-family: var(--primary-font);
  color: var(--black) !important;
}
p {
  font-size: 16px;
  font-weight: 500;
  color: var(--black);
  line-height: 28px;
}
button {
  border: 0;
}
.primary-btn {
  padding: 10px 25px 9px 25px;
  background: var(--theme_color);
  color: var(--white);
  text-decoration: none;
  font-size: 16px;
  font-family: var(--primary-font);
  font-weight: var(--font-regular);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;
  &:hover {
    background: #0c8eec;
  }
  @media (max-width: 767.98px) {
    height: 35px;
    font-size: 14px;
  }
}
