.header {
  width: 100%;
  padding: 30px 0;
  position: fixed;
  z-index: 1;
  transition: 0.3s;
  @media (max-width: 575.98px) { 
    padding: 10px 0;
   }
  &.sticky {    
    background: var(--header-bg);
    padding: 15px 0;
    transition: 0.5s;
    top: 0;
    backdrop-filter: blur(20px);
  }
  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo-section {
      width: 194px;
      a {
        cursor: pointer;
      }
      @media (max-width: 575.98px) { 
        width: 170px;
       }
       @media (max-width: 430.98px) { 
        width: 130px;
       }
    }
    .right-section {
      display: flex;
      gap: 15px;
      .open-nav {
        background: none;
        display: flex;
        align-items: center;
        @media (min-width: 1200px) {
          display: none;
        }
      }

      .header-nav {
        display: flex;

        .close-nav {
          position: absolute;
          right: 10px;
          top: 10px;
          background: none;
          border: none;
          @media (min-width: 1200px) {
            display: none;
          }
        }
        //display: none;
        @media (max-width: 1199.98px) {
          position: fixed;
          width: 250px;
          height: 100vh;
          background: var(--open-nav-bg);
          top: 0;
          right: -250px;
          transition: 0.3s;
          &.open {
            right: 0;
          }
        }
        .nav-items {
          display: flex;
          align-items: center;
          @media (max-width: 1199.98px) {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            padding: 0 10px;
            gap: 30px;
          }
          a {
            padding: 0 15px;
            color: var(--white);
            font-family: var(--secondary-font);
            cursor: pointer;
            &:hover {
              color: var(--theme_color);
              transition: 0.3s;
            }
          }
        }
        // .navbar {
        //     padding: 0;
        //     .navbar-nav {
        //         a {
        //             padding: 0 15px;
        //             color: var(--white);
        //             font-family: var(--secondary-font);
        //             cursor: pointer;
        //             &:hover {
        //                 color: var(--theme_color);
        //                 transition: .3s;
        //             }
        //         }
        //         .nav-link {
        //             padding: 0 15px;
        //             color: var(--white);
        //             font-family: var(--secondary-font);
        //             &:hover {
        //                 color: var(--theme_color);
        //             }
        //         }
        //     }
        // }
      }
      .right-button {
        @media (max-width: 430.98px) { 
          display: none;
        }
      }
    }
  }
}

.header-nav .active {
  transition: 0.3s;
  text-decoration: none;
  color: var(--theme_color) !important    ;
}
