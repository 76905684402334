.footer {
    .footer-top {
        background: var(--footer-bg);
        padding: 40px 0;
        h2 {
            margin-bottom: 30px;
            font-size: 18px !important;
            font-weight: var(--font-medium) !important;
            font-family: var(--primary-font);
            color: var(--black) !important;
            @media (max-width: 991.98px) {
                margin-bottom: 15px;
            }
        }
        .logo-section {
            @media (max-width: 991.98px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 30px;
            }
            .logo {
                width: 293px;
                @media (max-width: 1199.98px) { 
                    width: 180px;
                 }
            }
            p {
                font-size: 18px;
                font-family: var(--secondary-font);
                font-weight: var(--font-medium);
                color: var(--black);
            }
            .social-media {                
                display: flex;                
                gap: 10px;
                .social-icon {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background: var(--social-bg);
                    transition: .3s;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:hover {
                        background: var(--theme_color);
                        svg {
                            path {
                                fill: var(--white);
                            }
                        }
                    }
                }
              }
                             
        }
        .service-link-section {
            display: flex;
            justify-content: center;
            @media (max-width: 991.98px) {
                justify-content: flex-start;
            }
            @media (max-width: 767.98px) {
                justify-content: center;
            }
            .service-link-wrap {
                .service-link {
                    display: flex;
                    gap: 30px;
                    ul {
                        padding: 0;
                        margin: 0;
                        li {
                            list-style: none;
                            position: relative;
                            padding-left: 25px;
                            margin-bottom: 5px;
                            &::before {
                                width: 15px;
                                height: 6px;
                                border-radius: 5px;
                                background: var(--theme_color);
                                content: '';
                                position: absolute;
                                top: 10px;
                                left: 0;
                            }
                            a {
                                text-decoration: none;
                                font-size: 14px;
                                font-weight: var(--font-regular);
                                color: var(--black);
                                &:hover {
                                    color: var(--theme_color);
                                }
                            }
                        }
                    }
                  }
            }
        }
        .get-in-touch {
            @media (max-width: 767.98px) {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .contacts {
                ul {
                    padding: 0;
                    margin: 0;
                    @media (max-width: 767.98px) {
                        display: flex;
                        gap: 30px;
                    }
                    li{
                        list-style: none;
                        margin-bottom: 10px;
                        font-size: 14px;
                        font-family: var(--secondary-font);
                        color: var(--black);
                        font-weight: var(--font-medium);
                        span {
                            margin-right: 5px;
                        }
                        a {
                            text-decoration: none;
                            color: var(--black);
                            &:hover {
                                color: var(--theme_color);
                            }
                        }
                    }
                }
            }
        }
        
          
        }
    .footer-bottom {
        width: 100%;
        height: 60px;
        background: var(--theme_color);
        font-size: 12px;
        color: var(--white);
        font-family: var(--secondary-font);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}