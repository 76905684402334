.banner-container {
  height: auto;
  overflow: hidden;
  position: relative;
  .video-wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    .video {
      width: 100%;
      height: 100%;
      object-fit: cover; /* Ensures the video covers the entire container */
      //margin-top: -60px;
    }
  }
  .banner-content {
    width: 100%;
    position: absolute;
    top: calc(50% - 180px);
    @media (max-width: 575.98px) {
      text-align: center;   
    }
    @media (max-width: 430.98px) {
      top: calc(50% - 220px);
      }
    
    .banner-text {
      font-family: var(--primary-font);
      font-size: 40px;
      font-weight: 600;
      color: var(--white);
      @media (max-width: 991.98px) {
        font-size: 30px;
      }
      @media (max-width: 575.98px) {
        font-size: 25px;        
      }
      @media (max-width: 375.98px) {
        font-size: 20px;        
      }
      
      span {
        display: block;
        font-size: 80px;
        color: var(--theme_color);
        font-weight: 700;
        line-height: 100px;
        @media (max-width: 991.98px) {
          font-size: 60px;
          line-height: 70px;
        }
        @media (max-width: 575.98px) {
          font-size: 50px;
          line-height: 75px;
        }
        @media (max-width: 375.98px) {
          font-size: 40px;
          line-height: 60px;     
        }
        
      }
    }
    .banner-btn {
      //height: 45px;
      padding: 10px 25px 9px 25px;
      margin-top: 20px;
      background: var(--theme_color);
      color: var(--white);
      text-decoration: none;
      font-size: 16px;
      font-family: var(--primary-font);
      font-weight: var(--font-regular);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 25px;
      cursor: pointer;
      &:hover {
        background: #0C8EEC;
    }
      @media (max-width: 767.98px) {
        height: 35px;
        font-size: 14px;
      }
    }
    .primary-btn {
      height: 45px;
      padding: 0 20px;
      margin-top: 20px;
      @media (max-width: 767.98px) {
        height: 35px;
        font-size: 14px;
      }
    }
  }
  .social-media {
    position: absolute;
    right: 30px;
    top: calc(50% - 70px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 670.98px) {
      flex-direction: row;
      bottom: 30px;
      top: auto;
      right: 50px;
    }
    @media (max-width: 575.98px) {
      right: 15px;
      bottom: 20px;
    }
    // @media (max-width: 430.98px) {
    //     display: none;
    //   }

    .social-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: var(--social-bg);
      transition: 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: var(--theme_color);
        svg {
          path {
            fill: var(--white);
          }
        }
      }
    }
  }
}
.section-row {
  display: flex;
  gap: 50px;
  @media (max-width: 767.98px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 430.98px) {
    gap: 10px;
  }
  .section-col {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 767.98px) {
      width: 100%;
    }
  }
}
.about-section {
  padding: 100px 0;
  height: 100vh;
  display: flex;
  align-items: center;
  @media (max-width: 1024.98px) {
    height: auto;
    padding: 50px 0;
  }
  @media (max-width: 430.98px) {
    padding: 20px 0;
  }
}
.whyWe-section {
  padding: 100px 0;
  background: var(--bg-1);
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 1024.98px) {
    height: auto;
    padding: 50px 0;
  }
  @media (max-width: 430.98px) {
    padding: 20px 0;
  }
  .section-row {
    flex-direction: row-reverse;
    @media (max-width: 767.98px) {
      flex-direction: column-reverse;
    }
  }
  .style-image1 {
    position: absolute;
    left: 10%;
    top: -100px;
    @media (max-width: 767.98px) {
      display: none;
    }
  }
}
.contact-section {
  padding: 100px 0;
  height: 100vh;
  display: flex;
  align-items: center;
  @media (max-width: 1024.98px) {
    height: auto;
    padding: 50px 0;
  }
  @media (max-width: 430.98px) {
    padding: 20px 0;
  }
  .contact {
    h1 {
      margin-bottom: 0 !important;
    }
    p {
      font-size: 18px;
      font-family: var(--secondary-font);
    }
    .contact-form {
      margin-top: 50px;
      position: relative;
      @media (max-width: 430.98px) {
        margin-top: 0;
      }
      .all-text-danger {
        position: absolute;
        top: -20px;
        color: var(--red);
      }
      .input-wrap {
        position: relative;
        .text-danger {
          position: absolute;
          bottom: -20px;
          font-size: 12px;
        }
      }
      label {
        font-family: var(--secondary-font);
        font-size: 18px;
        color: var(--black);
        margin-bottom: 8px;
      }
      .asterisk {
        margin-left: 5px;
        color: var(--theme_color);
      }
      input {
        font-family: var(--secondary-font);
        font-size: 14px;
        color: var(--black);
        margin-bottom: 20px;
        height: 45px;
      }
      textarea {
        resize: none;
        font-family: var(--secondary-font);
        font-size: 14px;
        color: var(--black);
      }
      .button-wrap {
        position: relative;
        margin-top: 30px;
        display: inline-block;
        button {
          height: 45px;
          padding: 0 25px;        
        }
        .loader {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: var(--theme_color_transparent);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
        }
      }      
      p {
        margin-bottom: 0;
      }
    }
  }
  .address-wrap {
    border: dashed 1px var(--border);
    padding: 30px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 991.98px) {
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
    }
    @media (max-width: 767.98px) {
      flex-direction: row;
      align-items: center;
    }
    .country {
      font-size: 30px;
      text-transform: uppercase;
      font-family: var(--primary-font);
      font-weight: 700;
      color: var(--theme_color);
    }
    .address {
      font-size: 16px;
      font-family: var(--secondary-font);
      color: var(--black);
      font-weight: 700;
      span {
        display: block;
      }
    }
    .contacts {
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 5px;
        
        li {
          list-style: none;
          margin-top: 5px;
          font-size: 16px;
          font-family: var(--secondary-font);
          color: var(--black);
          font-weight: 700;
          display: flex;
          align-items: center;
          span {
            margin-right: 8px;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: var(--theme_color);
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              path {
                fill: var(--white)
              }
            }
          }
          a {
            text-decoration: none;
            color: var(--black);
          }
        }
      }
    }
  }
  .map {
    margin-top: 40px;
  }
}
