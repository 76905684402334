:root {
    --black: #2D2D2D;
    --white: #fff;
    --theme_color: #1778BE;
    --social-bg: rgba(23, 120, 190, 0.2);
    --bg-1: #F5F5FF;
    --bg-2: #F9FDFF;
    --footer-bg: #F3F7F8;
    --header-bg: rgba(0, 13, 30, 0.5);
    --gray: #AFAFAF;
    --border: #E4E4E4;
    --open-nav-bg: #000d1e;
    --red: #dc3545;
    --theme_color_transparent: rgba(23, 120, 190, 0.8);


    
    --primary-font: "Public Sans", sans-serif;
    --secondary-font: "Nunito", sans-serif;   
    --font-regular: 400;
    --font-medium : 500;
    --font-bold : 700;
}